.module-card {
  background: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #3a3a3a;
  transition: all 0.3s;
  padding: 12px 15px;
  position: relative;

  &.module-selected,
  &:hover {
    background: #000000;
    color: #b6b4b3;
  }

  .title {
    font-size: 23px;
    line-height: 26px;
    word-break: break-all;
  }

  .type {
    font-size: 17px;
    line-height: 19px;
  }

  button {
    padding: 0;
    height: 18px;
    width: 18px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 8px;
    bottom: 8px;

    &:disabled {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  &.module-selected,
  &:hover {
    button {
      color: #b6b4b3;

      &:disabled {
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  &.cursor-block {
    cursor: not-allowed;
  }
}
