.ant-form legend.role-legend {
  margin: 0;
  width: auto;
  border-bottom: none;
  margin-bottom: 5px;
}

.listview
  .roles-table.ant-table-wrapper
  .ant-table
  table
  tbody.ant-table-tbody
  td {
  border-bottom: 1px solid #9d9d9d;
  padding: 12px 16px;
}

.roles-table.ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.module-name {
  opacity: 0.6;
}
