/*category card css start here*/
.category-list {
  .category-card {
    max-width: 100%;
    min-width: 100%;
    padding-top: 148px;
    margin-bottom: 16px;

    @media @screen767 {
      padding-top: 84px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .category-title {
      .topic {
        font: @tq-display-h2;
        max-width: 100%;

        @media @screen767 {
          padding-bottom: 12px;
          font: @tq-display-h4;
        }

        &:before {
          width: 58px;
          border-bottom: 5px solid;

          @media @screen767 {
            width: 34px;
            border-bottom: 4px solid;
          }
        }
      }
    }
  }
}

.category-card {
  max-width: 248px;
  min-width: 248px;
  height: 324px;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  padding-top: 324px;
  .relative;

  .lazy-load-image {
    position: absolute;
    z-index: 1;
    inset: 0;
  }

  &::before {
    content: '';
    .absolute;
    .h-full;
    .w-full;
    inset: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.8) 100%
    );
    z-index: 2;
  }

  .btn-delete {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0 !important;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    display: none;
    z-index: 5;
    color: white;
  }

  @media @screen767 {
    max-width: 160px;
    min-width: 160px;
    padding-top: 208px;
  }

  &:hover {
    color: inherit;
    .btn-delete {
      display: flex;
    }
    .category-title {
      .topic::before {
        width: 80px;
      }
    }
  }

  &.category-grid {
    max-width: 256px;
    min-width: 256px;
    padding-top: 63.274%;
    display: block;

    @media @screen767 {
      padding-top: 63.29%;
    }

    .category-title {
      padding: 20px;

      @media @screen767 {
        padding: 16px;
      }

      .topic {
        padding-bottom: 0;

        @media @screen767 {
          font: @tq-display-h5;
        }
      }
    }
  }

  &.categorylist {
    max-width: 100%;
    min-width: 100%;
    padding: 0;

    .category-title {
      position: relative;
      padding: 32px 24px;

      @media @screen767 {
        padding: 24px 12px;
      }

      .topic {
        @media @screen767 {
          padding-bottom: 12px;
        }
      }
    }
  }

  &.category-lending {
    border-radius: 0;
    max-width: 100%;
    min-width: 100%;
    display: block;
    padding-top: 228px;

    @media @screen767 {
      padding-top: 160px;
    }

    .category-title {
      padding: 32px 48px;

      @media @screen767 {
        padding: 16px;
      }

      .topic {
        font: @tq-display-h1;
        letter-spacing: 0.02em;

        @media @screen767 {
          font: @tq-display-h3;
          letter-spacing: 0.01em;
          margin-bottom: 12px;
        }

        &:before {
          width: 62px;

          @media @screen767 {
            width: 36px;
          }
        }
      }
    }
  }

  .category-title {
    .absolute;
    .h-full;
    .w-full;

    .d-flex;
    .align-end;
    .p-16;
    left: 0;
    top: 0;
    z-index: 3;

    .topic {
      .m-0;
      padding-bottom: 16px;
      width: 100%;
      .relative;
      font: @tq-display-h4;
      letter-spacing: 0.01em;
      .text-white-90;

      @media @screen767 {
        font: @tq-display-h4;
      }
    }
  }
}

.category-slider {
  .slick-slide {
    margin: 0 8px;

    @media @screen767 {
      margin: 0 6px;
    }
  }

  .slick-slide {
    width: calc(calc(100vw - 175px) / 6.1);

    @media @screen1450 {
      width: calc(calc(100vw - 175px) / 5.1);
    }

    @media @screen1150 {
      width: calc(calc(100vw - 140px) / 4.1);
    }

    @media @screen1024 {
      width: calc(calc(100vw - 130px) / 3.1);
    }

    @media @screen767 {
      width: calc(calc(100vw - 39px) / 3.1);
    }

    @media @screen540 {
      width: calc(calc(100vw - 39px) / 2.1);
    }
  }

  .category-card {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    display: block;

    .category-title {
      padding: 24px;

      @media @screen767 {
        padding: 16px;
      }
    }
  }
}

.category-grid {
  .category-card {
    max-width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .section-header {
    margin-bottom: 16px;
  }
}

/*category card css end here*/
